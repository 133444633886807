import React, { useEffect, useRef, useState } from "react";
import { BsGoogle } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [name, setnameRegister] = useState();
  const [errMsg, setErrMsg] = useState("");
  const [registeredEmail, setRegisteredEmail] = useState("");

  const [isSubmitted, setISSubmitted] = useState(false);

  const errRef = useRef();

  const fetchLogin = async () => {
    axios
      .post("https://api.leadqode.com/api/users/login", { email, password })
      .then((res) => {
        secureLocalStorage.setItem("token", res.data.token);
        secureLocalStorage.setItem("role", res.data.role);
        secureLocalStorage.setItem("loggedIn", true);

        toast.success("You are logged in");
        setErrMsg("You are logged in");

        setTimeout(() => {
          window.location.href = "/leadai";
        }, 2000);
      })
      .catch((err) => {
        if (!err?.response) {
          toast.error("No server response");
        } else if (err.response?.status === 404) {
          toast.error("Email not found");
        } else if (err.response?.status === 400) {
          toast.error("Incorrect password");
        }
        // errRef.current.focus();
      });
  };

  const handleSigninSubmit = async (e) => {
    e.preventDefault();
    try {
      await fetchLogin();
    } catch (error) {
      if (error?.response) {
        if (error.response.status === 404) {
          toast.error("Email not found");
        } else if (error.response.status === 400) {
          toast.error("Please add all fields");
        }
      } else {
        toast.error("No server response");
      }
    }
  };

  const fetchRegister = async () => {
    try {
      await axios.post("https://api.leadqode.com/api/users/register", {
        name,
        email,
        password,
      });

      setErrMsg("Successfully registered! You can login now");
      toast.success("Registered successfully");
      setISSubmitted(true);
      setnameRegister("");
      setRegisteredEmail(email);
      setPassword("");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);

      document.getElementById("signIn").click();
    } catch (error) {
      setErrMsg(error.response.data.message);
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    try {
      await fetchRegister();
    } catch (error) {
      if (error?.response) {
        setErrMsg(error.response.data.message);
      } else {
        setErrMsg("No server response");
      }
      setTimeout(() => setErrMsg(""), 3000);
    }
  };
  useEffect(() => {}, []);

  useEffect(() => {
    const signUpButton = document.getElementById("signUp");
    const signInButton = document.getElementById("signIn");
    const container = document.getElementById("container");

    const handleSignUpClick = () => {
      container.classList.add("right-panel-active");
    };

    const handleSignInClick = () => {
      container.classList.remove("right-panel-active");
    };

    signUpButton.addEventListener("click", handleSignUpClick);
    signInButton.addEventListener("click", handleSignInClick);

    return () => {
      signUpButton.removeEventListener("click", handleSignUpClick);
      signInButton.removeEventListener("click", handleSignInClick);
    };
  }, []);

  return (
    <div className="login_form">
      <div className="container" id="container">
        {/* =======================SIGN UP================================= */}
        <div className="form-container sign-up-container">
          <form className="login_form_form" onSubmit={handleRegisterSubmit}>
            <h1
              onClick={() => {
                let account = document.querySelector(".sign-up-container");
                if (account) {
                  if (account.classList.contains("active")) {
                    account.classList.remove("active");
                  } else {
                    account.classList.add("active");
                  }
                  let xIcon = document.querySelector(".x-icon");
                  if (xIcon) {
                    xIcon.style.display = account.classList.contains("active")
                      ? "inline-block"
                      : "none";
                  }
                }
              }}
            >
              Create Account
              <RiCloseCircleLine className="x-icon">X</RiCloseCircleLine>
            </h1>
            {/* <div className="social-container">
              <a href="#" className="login_form_a">
                <BsGoogle />
              </a>
            </div>
            <span className="login_form_span">
              or use your email for registration
            </span> */}
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setnameRegister(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br></br>
            <button className="button_web">Sign Up</button>

            {errMsg && <p className="error-message">{errMsg}</p>}
          </form>
        </div>
        {/* =======================END SIGN UP================================= */}

        {/* =======================SIGN IN================================= */}
        <div className="form-container sign-in-container">
          <form className="login_form_form" onSubmit={handleSigninSubmit}>
            <h1>Sign in</h1>
            {/* <div className="social-container">
              <a href="#" className="login_form_a">
                <BsGoogle />
              </a>
            </div>
            <span className="login_form_span">or use your account</span> */}

            <input
              type="email"
              placeholder="Email"
              value={email || registeredEmail}
              onChange={(e) => setEmail(e.target.value)}
            />

            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br></br>
            {/* <a className="login_form_a" href="#">Forgot your password?</a> */}
            <button className="button_web">Sign In</button>
            {errMsg && <p className="error-message">{errMsg}</p>}
          </form>
          {/* =======================END SIGN IN================================= */}
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1>Welcome Back!</h1>
              <p className="login_form_p">
                To keep using Lead AI us please login with your personal info
              </p>
              <button className="login_form_button_ghost" id="signIn">
                Sign In
              </button>
            </div>
            <div className="overlay-panel overlay-right">
              <h1>Hello, Friend!</h1>
              <p className="login_form_p">
                Enter your personal details and start using Lead AI
              </p>
              <button className="login_form_button_ghost" id="signUp">
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default Login;

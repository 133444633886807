import Login from "./Auth/Login/Login";
import LeadAi from "./LeadAi/LeadAi";
import { Route, Routes } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useEffect, useState } from "react";
function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const changeLoggedIn = () => {
    let loggedin = secureLocalStorage.getItem("loggedIn");
    if (loggedin) setIsLoggedIn(true);
    else setIsLoggedIn(false);
  };
  useEffect(() => {
    changeLoggedIn();
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          exact
          path="/leadai"
          element={isLoggedIn === true ? <LeadAi /> : <Login />}
        />

      </Routes>
    </div>
  );
}

export default App;

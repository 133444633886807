import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LeadAi.css";
import { Navigate, useNavigate } from "react-router-dom";
// import Navbarchatko from "../../components/Header/Navbarchatko";
import secureLocalStorage from "react-secure-storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineDelete } from "react-icons/ai";
import Connect from "../ConnectDot/Connect";

function LeadAi(props) {
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState("");
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  // Fetch history using GET request
  const fetchHistory = async () => {
    try {
      const token = secureLocalStorage.getItem("token");
      const response = await axios.get(
        "https://api.leadqode.com/api/history/gethistorybyuser",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setHistory(response.data.history);
    } catch (error) {
      console.error(error);
    }
  };

  // Add chat to history using POST request
  const addToHistory = async (newChat) => {
    try {
      const token = secureLocalStorage.getItem("token");
      const response = await axios.post(
        "https://api.leadqode.com/api/history/createhistory/",
        newChat,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setHistory([...history, response.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteHistory = async (id) => {
    try {
      const token = secureLocalStorage.getItem("token");
      const response = await axios.delete(
        `https://api.leadqode.com/api/history/deletehistory/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.error("Deleted Successfully", 2000);
      setHistory(history.filter((item) => item.id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const [isCopied, setIsCopied] = useState(false);
  const [displayedHistory, setDisplayedHistory] = useState(3);

  const handleClick = async () => {
    setLoading(true);

    const options = {
      method: "POST",
      url: "https://chatgpt-gpt-3-5.p.rapidapi.com/ask",
      headers: {
        "content-type": "application/json",
        "X-RapidAPI-Key": "6bf3dd6e66mshb6f4625464447abp1d045ejsnf69c73442a47",
        "X-RapidAPI-Host": "chatgpt-gpt-3-5.p.rapidapi.com",
      },
      data: {
        query: prompt,
      },
    };

    try {
      const response = await axios.request(options);
      setResult(response.data.response);

      // Add chat to history
      const newChat = { prompt, result: response.data.response };
      addToHistory(newChat);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const handleHistoryItemClick = (item) => {
    setPrompt(item.question);
    setResult(item.answer);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(result);
    setIsCopied(true);
  };

  const loadMoreItems = () => {
    setDisplayedHistory(displayedHistory + 3);
  };

  useEffect(() => {
    // Fetch history when the component mounts
    fetchHistory();
  }, [history]);


  const handleLogout = () => {
    secureLocalStorage.clear();
   
    navigate('/');
  };
  const handleBacktoleadqode = () => {
    window.location.href = 'https://leadqode.com';
  };


  return (
    <>
      {/* <Navbarchatko isLoggedIn={props.isLoggedIn} setIsLoggedIn={props.setIsLoggedIn} /> */}

      <main className="chatko_main_container">
        <div className="background_container">
          <Connect />
        </div>

        <div className="lead_history_container">
          <div className="chatko_history">
            <h1>History</h1>
            <div>
            <button className="button_web" onClick={handleBacktoleadqode}>
                    BACK TO LEADQODE
                  </button>
                  <button className="button_web" onClick={handleLogout}>
                    LOGOUT
                    </button>

            </div>
            {Array.isArray(history) && (
              <ul>
                {history.slice(0, displayedHistory).map((item, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      handleHistoryItemClick(item.data[0]);
                      setIsCopied(false);
                    }}
                  >
                    {item.data[0].question.split(" ").slice(0, 3).join(" ")}
                    <AiOutlineDelete onClick={() => deleteHistory(item._id)}>
                      Delete
                    </AiOutlineDelete>
                  </button>
                ))}
              </ul>
            )}
            <br />
            {Array.isArray(history) && displayedHistory < history.length && (
              <button className="button_web" onClick={loadMoreItems}>
                Load More
              </button>
            )}
          </div>

          <div className="chatko_container">
            <div className="chatko_heading">
              <h1>Lead AI</h1>
              <p>Powered by LeadQode</p>
            </div>

            <div className="leadai_container">
              <nav className="chatko_copy">
                {result && (
                  <button className="button_web" onClick={handleCopyClick}>
                    {isCopied ? "Copied" : "Copy"}
                  </button>
                )}
              </nav>
              <br></br>

              <div className="chatko_pre_container">
                <p className="chatko_pre">{result}</p>
              </div>
              <br />

              <textarea
                className="chatko_textarea"
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="What would you like to know.."
              />
              <div className="chatko_buttons">
                <button
                  className="button_web"
                  onClick={(e) =>
                    setPrompt("") + setResult("") + setIsCopied(false)
                  }
                  disabled={loading || prompt.length === 0}
                >
                  Clear
                </button>
                <button
                  className="button_web"
                  onClick={handleClick}
                  disabled={loading || prompt.length === 0}
                >
                  {loading ? "Generating..." : "Generate"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-right" />
      </main>
    </>
  );
}

export default LeadAi;
